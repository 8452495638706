@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  /* height: 100%; */
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
